import * as React from "react"
import { useRef, useState, useMemo, useCallback, useEffect } from "react"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import useMediaQuery from "@mui/material/useMediaQuery"

function KeypointImage({
  imageUrl,
  onShapeClick,
  onShapeMouseEnter,
  onShapeMouseLeave,
  onShapeDrop,
  fillColorFunction,
  strokeColorFunction,
  keypoints1 = [],
  keypoints2 = [],
  matches = [],
  img1Shape = [],
  matchesMask = [],
  corners = [],
  maskImageUrl = null,
}) {
  // console.info("Load keypoint image")
  const [img1Rows, img1Cols] = img1Shape || [0, 0]
  const svgRef = useRef()
  const [container, setContainer] = useState(null)
  const backgroundColor = "transparent"
  const scaleUp = true
  const [selectedKey, setSelectedKey] = useState("")
  const [hoveredKey, setHoveredKey] = useState("")

  const [containerWidth, setContainerWidth] = useState(0)
  const [containerHeight, setContainerHeight] = useState(0)

  const [imageNaturalWidth, setImageNaturalWidth] = useState(0)
  const [imageNaturalHeight, setImageNaturalHeight] = useState(0)
  const isDesktop = useMediaQuery("(min-device-width: 1250px)")
  const zoomFactor = isDesktop ? 1 : 8
  const imageScale = useMemo(() => {
    if (
      containerWidth === 0 ||
      containerHeight === 0 ||
      imageNaturalWidth === 0 ||
      imageNaturalHeight === 0
    )
      return 0
    const scale = Math.min(
      containerWidth / imageNaturalWidth,
      containerHeight / imageNaturalHeight
    )
    return scaleUp ? scale : Math.max(scale, 1)
  }, [
    scaleUp,
    containerWidth,
    containerHeight,
    imageNaturalWidth,
    imageNaturalHeight,
  ])
  const handleResize = useCallback(() => {
    if (container !== null) {
      const rect = container.getBoundingClientRect()
      setContainerWidth(rect.width)
      setContainerHeight(rect.height)
    } else {
      setContainerWidth(0)
      setContainerHeight(0)
    }
  }, [container])

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [handleResize])

  const handleImageOnLoad = (image) => {
    setImageNaturalWidth(image.naturalWidth)
    setImageNaturalHeight(image.naturalHeight)
  }

  useEffect(() => {
    if (!imageUrl) return
    const image = new Image()
    image.onload = () => handleImageOnLoad(image)
    image.src = imageUrl
  }, [imageUrl])

  useEffect(() => {
    if (!maskImageUrl) return
    const image = new Image()
    image.onload = () => handleImageOnLoad(image)
    image.src = maskImageUrl
  }, [maskImageUrl])

  const handleShapeClick = (key) => {
    if (onShapeClick) {
      onShapeClick(key)
    }
    setSelectedKey(key)
  }

  const handleShapeMouseEnter = (key) => {
    if (onShapeMouseEnter) {
      onShapeMouseEnter(key)
    }
    setHoveredKey(key)
  }

  const handleShapeMouseLeave = (key) => {
    if (onShapeMouseLeave) {
      onShapeMouseLeave(key)
    }
    setHoveredKey("")
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDrop = (key) => (e) => {
    e.preventDefault()
    if (onShapeDrop) {
      onShapeDrop(key)(e)
    }
  }
  // console.info("IMG1 SHAPE", img1Shape, "IMG1 ROWS", img1Rows, "IMG1 COLS", img1Cols)
  // console.info("NATURAL WIDTH", imageNaturalWidth, "NATURAL HEIGHT", imageNaturalHeight, "IMAGE SCALE", imageScale, "CONTAINER WIDTH", containerWidth, "CONTAINER HEIGHT", containerHeight)
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor,
      }}
      ref={(el) => setContainer(el)}
    >
      <TransformWrapper initialScale={1} centerOnInit>
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            <div className="tools">
              <button onClick={() => zoomIn()}>+</button>
              <button onClick={() => zoomOut()}>-</button>
              <button onClick={() => resetTransform()}>x</button>
            </div>
            <TransformComponent>
              <img
                src={imageUrl}
                alt="snapshot"
                style={{ maxWidth: "100vw", maxHeight: "100vh" }}
              />
              <img
                src={maskImageUrl}
                alt="snapshot"
                style={{ maxWidth: "100vw", maxHeight: "100vh" }}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ position: "absolute", backgroundColor: "transparent" }}
                width="100%"
                height="100%"
                viewBox={`0 0 ${imageNaturalWidth} ${imageNaturalHeight}`}
              >
                {false &&
                  matches.map((match, index) => {
                    const { queryIdx, trainIdx } = match
                    const isInlier = matchesMask[index] === 1
                    const kp1 = keypoints1[queryIdx]
                    const kp2 = keypoints2[trainIdx]
                    const [p1, p2] = [kp1.pt, kp2.pt]
                    const color = isInlier ? "green" : "red"
                    const isSelected = selectedKey === queryIdx
                    const isHovered = hoveredKey === queryIdx
                    let fillOpacity = "0.0"

                    let strokeOpacity = "0.0"
                    let circleStrokeOpacity = "1.0"
                    if (isSelected) {
                      strokeOpacity = "1.0"
                      fillOpacity = "0.75"
                      circleStrokeOpacity = "1.0"
                    } else if (isHovered) {
                      strokeOpacity = "1.0"
                      circleStrokeOpacity = "1.0"
                      fillOpacity = "0.5"
                    }

                    return (
                      <React.Fragment key={index}>
                        <line
                          x1={p1[0]}
                          y1={p1[1]}
                          x2={p2[0] + img1Cols}
                          y2={p2[1]}
                          stroke={color}
                          strokeWidth="2"
                          strokeOpacity={strokeOpacity}
                          onClick={() => handleShapeClick(queryIdx)}
                          onMouseEnter={() => handleShapeMouseEnter(queryIdx)}
                          onMouseLeave={() => handleShapeMouseLeave(queryIdx)}
                        />
                        <circle
                          cx={p1[0]}
                          cy={p1[1]}
                          r={kp1.size} // adjust this as needed
                          stroke={color}
                          strokeWidth="2"
                          fill={color}
                          strokeOpacity={circleStrokeOpacity}
                          fillOpacity={fillOpacity}
                          onClick={() => handleShapeClick(queryIdx)}
                          onMouseEnter={() => handleShapeMouseEnter(queryIdx)}
                          onMouseLeave={() => handleShapeMouseLeave(queryIdx)}
                        />
                        <circle
                          cx={p2[0] + img1Cols}
                          cy={p2[1]}
                          r={kp2.size} // adjust this as needed
                          stroke={color}
                          strokeWidth="2"
                          fill={color}
                          strokeOpacity={circleStrokeOpacity}
                          fillOpacity={fillOpacity}
                          onClick={() => handleShapeClick(queryIdx)}
                          onMouseEnter={() => handleShapeMouseEnter(queryIdx)}
                          onMouseLeave={() => handleShapeMouseLeave(queryIdx)}
                        />
                      </React.Fragment>
                    )
                  })}
                {corners.map((corner, index) => {
                  const color = "blue"
                  const isSelected = false
                  const isHovered = false
                  // const isSelected = selectedKey === queryIdx
                  // const isHovered = hoveredKey === queryIdx
                  let fillOpacity = "0.0"

                  let strokeOpacity = "0.0"
                  let circleStrokeOpacity = "1.0"
                  if (isSelected) {
                    strokeOpacity = "1.0"
                    fillOpacity = "0.75"
                    circleStrokeOpacity = "1.0"
                  } else if (isHovered) {
                    strokeOpacity = "1.0"
                    circleStrokeOpacity = "1.0"
                    fillOpacity = "0.5"
                  }

                  return (
                    <React.Fragment key={index}>
                      <circle
                        cx={corner[0]}
                        cy={corner[1]}
                        r={10} // adjust this as needed
                        stroke={color}
                        strokeWidth="2"
                        fill={color}
                        strokeOpacity={circleStrokeOpacity}
                        fillOpacity={fillOpacity}
                        // onClick={() => handleShapeClick(queryIdx)}
                        // onMouseEnter={() => handleShapeMouseEnter(queryIdx)}
                        // onMouseLeave={() => handleShapeMouseLeave(queryIdx)}
                      />
                    </React.Fragment>
                  )
                })}
              </svg>
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
    </div>
  )
}

export default KeypointImage
