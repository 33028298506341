import React, { useState } from "react"
import Slider from "@mui/material/Slider"

const OverlayImage = ({ image1Url, image2Url, width, height }) => {
  const [opacity, setOpacity] = useState(0.5)

  const handleSliderChange = (event, newValue) => {
    setOpacity(newValue)
  }

  return (
    <div>
      <div
        style={{
          position: "relative",
          // width: width,
          // height: height,
          // backgroundColor: "green",
        }}
      >
        <img
          src={image1Url}
          alt="snapshot"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "fill",
            display: "block",
            // position: "absolute",
            // top: 0,
            // left: 0,
          }}
        />
        {image2Url && <img
          src={image2Url}
          alt="snapshot"
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            opacity: opacity,
            objectFit: "fill",
            display: "block",
            // filter: "url(#sobelFilter)",
          }}
        />}
        
      </div>
      <svg width="0px" height="0px">
          <filter id="sobelFilter" x="0%" y="0%" width="100%" height="100%">
            <feColorMatrix
              in="SourceGraphic"
              type="matrix"
              values="0.333 0.333 0.333 0 0
              0.333 0.333 0.333 0 0
              0.333 0.333 0.333 0 0
              0     0     0     1 0"
              result="grayImage"
            />
            <feColorMatrix
              in="SourceGraphic"
              type="matrix"
              values="0 0 0 0 1 
                      0 0 0 0 1 
                      0 0 0 0 1 
                      1 0 0 0 0"
              result="RChan"
            />
            <feColorMatrix
              in="grayImage"
              type="matrix"
              values="0.5 0 0 0 0.5 
                      0 0.5 0 0 0.5 
                      0 0 0.5 0 0.5 
                      0 0 0 1 0"
              result="centeredAroundHalf"
            />
            <feConvolveMatrix
              in="RChan"
              order="3"
              kernelMatrix="-1 0 1  
                  -2 0 2 
                  -1 0 1"
              result="Bver"
              divisor="1"
              bias="0.5"
            />
            <feConvolveMatrix
              in="RChan"
              order="3"
              kernelMatrix="-1 -2 -1  
                  0 0 0 
                  1 2 1"
              result="Bhor"
              divisor="1"
              bias="0.5"
            />
            {/* <feComposite
              in="Bver"
              in2="Bhor"
              operator="arithmetic"
              k1="0"
              k2="1"
              k3="1"
              k4="0"
              result="Bsum"
            /> */}
            {/* <feConvolveMatrix
              in="centeredAroundHalf"
              order="3"
              kernelMatrix="0 0 0  
                            0 1 0
                            0 0 0"
            /> */}
          </filter>
        </svg>
      <Slider
        value={opacity}
        onChange={handleSliderChange}
        aria-labelledby="opacity-slider"
        min={0}
        max={1}
        step={0.01}
        style={{ width: "80%", margin: "20px auto", display: "block" }}
      />
    </div>
  )
}

export default OverlayImage
