import * as React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import FindClimb from "./pages/findClimb"
import ShowClimb from "./pages/ShowClimb"
import SearchClimbs from "./pages/SearchClimbs"
import Home from "./pages/Home"
import Graph from "./pages/graph"
import Admin from "./admin/pages/Admin"
import Stats from "./pages/stats"
import Stream from "./pages/Stream"
import { QueryClient, QueryClientProvider } from "react-query"

// Create a client
const queryClient = new QueryClient()

const connectToBluetooth = async () => {
  if (!("bluetooth" in navigator)) {
    return
  }

  return navigator.bluetooth
    .requestDevice({
      // filters: [
      //   {
      //     services: ["4488b571-7806-4df6-bcff-a2897e4953ff"],
      //   },
      // ],
      // optionalServices: ["6e400001-b5a3-f393-e0a9-e50e24dcca9e"],
      acceptAllDevices: true,
    })
    .then((device) => {
      console.log("> Found " + device.name)
      console.log("Connecting to GATT Server...")
      return device.gatt.connect()
    })
    .then((server) =>
      server.getPrimaryService("6e400001-b5a3-f393-e0a9-e50e24dcca9e")
    )
    .then((service) =>
      service.getCharacteristic("6e400002-b5a3-f393-e0a9-e50e24dcca9e")
    )
}

async function mapSeries(array, asyncFn) {
  const results = []
  for (const item of array) {
    const result = await asyncFn(item)
    results.push(result)
  }
  return results
}

const sendClimbToWall = (characteristic: any, climb: any) => {
  return mapSeries(getBluetoothMessages(climb), (msg) =>
    characteristic.writeValue(msg)
  )
}

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      {/* <button onClick={connectToBluetooth}>connect to bluetooth</button> */}
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/find-climb" element={<FindClimb />} />
          <Route path="/search-climbs" element={<SearchClimbs />} />
          <Route path="/climb/:climbId" element={<ShowClimb />} />
          <Route path="/graph" element={<Graph />} />
          <Route path="/stats" element={<Stats />} />
          <Route path="/stream" element={<Stream />} />
        </Routes>
      </Router>
    </QueryClientProvider>
  )
}
