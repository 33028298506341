import React, { useRef, useState, useEffect } from "react"
import OverlayImage from "../home/OverlayImage"
import { downscale, dataURLToBlob } from "../../utils/imageFunctions"

const CameraStream = () => {
  const videoRef = useRef(null)
  const canvasRef = useRef(null)
  const [image, setImage] = React.useState(null)
  const [overlayImage, setOverlayImage] = React.useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [devices, setDevices] = useState([])
  const [selectedDeviceId, setSelectedDeviceId] = useState("")
  const [isCapturing, setIsCapturing] = useState(false)
  const [intervalId, setIntervalId] = useState(null)

  useEffect(() => {
    const getDevices = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices()
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      )
      setDevices(videoDevices)
      if (videoDevices.length > 0) {
        setSelectedDeviceId(videoDevices[0].deviceId)
      }
    }

    getDevices()
  }, [])

  useEffect(() => {
    const startCamera = async () => {
      try {
        const constraints = {
          video: {
            deviceId: selectedDeviceId
              ? { exact: selectedDeviceId }
              : undefined,
            width: { ideal: 1920 },
            height: { ideal: 1080 },
          },
        }
        const stream = await navigator.mediaDevices.getUserMedia(constraints)
        if (videoRef.current) {
          videoRef.current.srcObject = stream
          videoRef.current.onloadedmetadata = () => {
            console.info(
              "VIDEO LOADED: ",
              videoRef.current.videoWidth,
              videoRef.current.videoHeight
            )
            if (canvasRef.current) {
              canvasRef.current.width = videoRef.current.videoWidth
              canvasRef.current.height = videoRef.current.videoHeight
            }
            // Query the camera capabilities
            const videoTrack = stream.getVideoTracks()[0]
            const capabilities = videoTrack.getCapabilities()
            console.log("Camera capabilities:", capabilities)
          }
        }
      } catch (err) {
        console.error("Error accessing the camera: ", err)
      }
    }

    if (selectedDeviceId) {
      startCamera()
    }

    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        const tracks = videoRef.current.srcObject.getTracks()
        tracks.forEach((track) => track.stop())
      }
    }
  }, [selectedDeviceId])

  useEffect(() => {
    fetchClimb()
  }, [selectedFile])

  useEffect(() => {
    if (isCapturing) {
      const id = setInterval(captureFrame, 500)
      setIntervalId(id)
    } else if (intervalId) {
      clearInterval(intervalId)
      setIntervalId(null)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [isCapturing])

  const handleTakePhoto = (dataUri) => {
    const reader = new FileReader()

    downscale(dataUri, 1280).then((resizedImage) => {
      setImage(resizedImage)
      const blob = dataURLToBlob(resizedImage)
      let extension = blob.type.split("/")[1]
      if (extension === "jpeg") {
        extension = "jpg"
      }
      const newFile = new File([blob], `camera-image.${extension}`, {
        type: blob.type,
      })
      setSelectedFile(newFile)
    })
  }
  const fetchClimb = async () => {
    if (selectedFile) {
      // console.log(selectedFile);
      setLoading(true)
      const formData = new FormData()
      formData.append("image", selectedFile)
      formData.append("streaming", true)

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL || ""}/api/find_climb`,
          {
            method: "POST",
            body: formData,
          }
        )
        const json = await response.json()
        const [encodedImage] = [json.query_image_hold_locations].map(
          (encodedImage) => {
            return `data:image/png;base64,${encodedImage}`
          }
        )
        const climbId = json.uuid
        setOverlayImage(encodedImage)

        // console.info(json);
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
  }

  const captureFrame = () => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext("2d")
      context.drawImage(
        videoRef.current,
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      )
      const frame = canvasRef.current.toDataURL("image/png")
      handleTakePhoto(frame)
    }
  }

  const handleDeviceChange = (event) => {
    setSelectedDeviceId(event.target.value)
  }

  const toggleCapture = () => {
    if (isCapturing) {
      setIsCapturing(false)
    } else {
      setIsCapturing(true)
    }
    if (isCapturing) {
      clearInterval(intervalId)
    } else {
      const intervalId = setInterval(captureFrame, 1000)
    }
  }

  return (
    <div>
      <select onChange={handleDeviceChange} value={selectedDeviceId}>
        {devices.map((device) => (
          <option key={device.deviceId} value={device.deviceId}>
            {device.label || `Camera ${device.deviceId}`}
          </option>
        ))}
      </select>
      <video
        ref={videoRef}
        autoPlay
        style={{ width: "100%", display: "none" }}
      />
      <OverlayImage
        image1Url={image}
        image2Url={overlayImage}
        width={canvasRef.current?.width}
        height={canvasRef.current?.height}
      />
      <canvas ref={canvasRef} style={{ display: "none" }} />
      <button onClick={toggleCapture}>{isCapturing ? "Stop" : "Start"}</button>
    </div>
  )
}

export default CameraStream
