import * as React from "react"
import { useState, useEffect } from "react"
import { Box, Button, Grid } from "@mui/material"
import { useDropzone } from "react-dropzone"
import { CircularProgress } from "@mui/material"
import { useNavigate } from "react-router-dom"
import CameraAltIcon from "@mui/icons-material/CameraAlt"
import { downscale, dataURLToBlob } from "../../utils/imageFunctions"
import "react-html5-camera-photo/build/css/index.css"
import Images from "./Images"
import ImageIcon from "@mui/icons-material/Image"
import KeypointImage from "./KeypointImage"
import OverlayImage from "./OverlayImage"
import { Slider } from "@mui/material"

const TARGET = "1"
const SOURCE = "2"
function MyDropzone({
  image,
  onDropFile,
  defaultImage = "/images/kilter.jpg",
}) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    // Accept only images
    accept: "image/*",

    onDrop: (acceptedFiles) => {
      // Do something with the files
      console.log(acceptedFiles)
      onDropFile(acceptedFiles[0])
    },
  })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {
        <img
          src={image || defaultImage}
          alt="snapshot"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      }
    </div>
  )
}

function KeypointTab() {
  const [state, setState] = useState({
    images: [],
    keypoints1: [],
    keypoints2: [],
    matches: [],
    img1Shape: [],
    warpedImage: null,
    trainImage: null,
  })
  const [images, setImages] = useState({
    [TARGET]: null,
    [SOURCE]: null,
  })
  const [selectedFiles, setSelectedFiles] = useState({
    [TARGET]: null,
    [SOURCE]: null,
  })
  const [threshold, setThreshold] = useState(0.75)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleCapture = (event) => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onloadend = () => {
      downscale(reader.result, 1024).then((resizedImage) => {
        setImage(resizedImage)
        const blob = dataURLToBlob(resizedImage)
        const newFile = new File([blob], file.name, { type: blob.type })
        setSelectedFile(newFile)
      })
    }
    reader.readAsDataURL(file)
  }

  const fetchKeypoints = async (targetFile, sourceFile) => {
    if (targetFile) {
      console.log(targetFile)
      setLoading(true)
      const formData = new FormData()
      formData.append("target", targetFile)
      if (sourceFile) {
        formData.append("source", sourceFile)
      }
      console.info("THRESHOLD: ", threshold)
      formData.append("threshold", threshold)

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL || ""}/api/display-keypoints`,
          {
            method: "POST",
            body: formData,
          }
        )
        const json = await response.json()
        console.info(json)
        const encodedImages = json.images
        const decodedImages = encodedImages.map((encodedImage) => {
          return `data:image/png;base64,${encodedImage}`
        })
        const [warpedImage, trainImage] = [json.warped_image, json.train_image].map(
          (encodedImage) => {
            return `data:image/png;base64,${encodedImage}`
          }
        )

        const { keypoints1, keypoints2, matches, img1_shape, matches_mask } =
          json
        setState({
          ...state,
          images: decodedImages,
          img1Shape: img1_shape,
          keypoints1,
          keypoints2,
          matches,
          matchesMask: matches_mask,
          warpedImage,
          trainImage,
        })
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    console.info("SELECTED FILES: ", selectedFiles)
    if (!selectedFiles[TARGET]) return
    fetchKeypoints(selectedFiles[TARGET], selectedFiles[SOURCE])
  }, [selectedFiles, threshold])

  const setImage = (key) => (image) => {
    setImages({ ...images, [key]: image })
  }

  const setFile = (key) => (file) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      downscale(reader.result, 1024).then((resizedImage) => {
        setImage(key)(resizedImage)
        const blob = dataURLToBlob(resizedImage)
        const newFile = new File([blob], file.name, { type: blob.type })
        setSelectedFiles({ ...selectedFiles, [key]: newFile })
      })
    }
    reader.readAsDataURL(file)
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Slider
          value={threshold}
          onChangeCommitted={(event, newValue) => setThreshold(newValue)}
          step={0.01}
          min={0.1}
          max={1}
          valueLabelDisplay="auto"
        />
        <Grid item xs={12} md={2}>
          <MyDropzone image={images[TARGET]} onDropFile={setFile(TARGET)} />
        </Grid>
        <Grid item xs={12} md={2}>
          <MyDropzone image={images[SOURCE]} onDropFile={setFile(SOURCE)} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {loading ? (
            <CircularProgress />
          ) : (
            <KeypointImage
              imageUrl={state?.images[0]}
              keypoints1={state?.keypoints1}
              keypoints2={state?.keypoints2}
              matches={state?.matches}
              img1Shape={state?.img1Shape}
              matchesMask={state?.matchesMask}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {loading ? (
            <CircularProgress />
          ) : (
            <OverlayImage
              image1Url={state?.trainImage}
              image2Url={state?.warpedImage}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default KeypointTab
