import * as React from "react"
import { useState, useEffect, useRef } from "react"
import {
  Box,
  Tab,
  Tabs,
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  Link,
  List,
  ListItem,
  ListItemText,
} from "@mui/material"
import { CircularProgress } from "@mui/material"
import { useParams } from "react-router-dom"
import { useQuery } from "react-query"
import TabbedView from "../TabbedView"

import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  MemoryRouter,
} from "react-router-dom"

export default function Stats({
  setters_all_time,
  setters_by_month,
  first_ascents_by_month,
}) {
  console.info(
    "STATS DATA: ",
    setters_all_time,
    setters_by_month,
    first_ascents_by_month
  )
  return (
    <Grid
      container
      sx={{ pl: { xs: "0", md: "0" }, backgroundColor: "transparent" }}
    >
      <Grid item xs={12} sx={{ backgroundColor: "transparent" }}>
        <TabbedView
          tabNames={[
            "All-time Setters",
            "Setters by Month",
            "First Ascents by Month",
          ]}
          tabs={[
            <Grid key={0} container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom align="center">
                  All-time Setters
                </Typography>
                <Grid container justifyContent="center" spacing={2}>
                  <List>
                    {setters_all_time.map((setter) => (
                      <ListItem key={setter[2]}>
                        <ListItemText
                          primary={setter[0]}
                          secondary={setter[1]}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Grid>,
            <Grid key={1} container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom align="center">
                  Monthly Setters
                </Typography>
                <Grid container justifyContent="center" spacing={2}>
                  <List>
                    {setters_by_month.map((setter) => (
                      <ListItem key={setter[0] + setter[1]}>
                        <ListItemText
                          primary={setter[0] + " " + setter[1]}
                          secondary={setter[2]}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Grid>,
            <Grid key={2} container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom align="center">
                  First Ascents
                </Typography>
                <Grid container justifyContent="center" spacing={2}>
                  <List>
                    {first_ascents_by_month.map((setter) => (
                      <ListItem key={setter[0] + setter[1]}>
                        <ListItemText
                          primary={setter[0] + " " + setter[1]}
                          secondary={setter[2]}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Grid>,
          ]}
        />
      </Grid>
    </Grid>
  )
}
