import * as React from "react"
import { Box, Tab, Tabs, AppBar } from "@mui/material"
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

export default function TabbedView({ tabNames, tabs }) {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div style={{ width: "100%" }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabbed panel"
          centered
          indicatorColor="secondary"
          textColor="secondary"
        >
          {tabNames.map((tn, i) => (
            <Tab label={tn} {...a11yProps(i)} key={i} />
          ))}
        </Tabs>
      </AppBar>

      {tabs.map((tv, i) => (
        <TabPanel value={value} index={i} key={i}>
          {tv}
        </TabPanel>
      ))}
    </div>
  )
}
