import * as React from "react"
import { useState, useEffect } from "react"
import { Box, Tab, Tabs, Button, Grid } from "@mui/material"
import AddImage from "../components/AddImage"

export default function FindClimb() {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Camera" />
          <Tab label="Upload" />
          <Tab label="Keypoint" />
        </Tabs>
      </Box>
      {value === 0 && <AddImage />}
      {value === 1 && <AddImage />}
      {value === 2 && <AddImage />}
    </Box>
  )
}
